import type { GatsbyBrowser } from 'gatsby';
import { getCookie } from '@lucid/utils';

// eslint-disable-next-line @typescript-eslint/no-misused-promises
const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  try {
    const preloadChart = document.createElement('iframe');
    preloadChart.style.display = 'none';
    preloadChart.src = 'https://lucid.app/documents/preloadChart';
    preloadChart.dataset.osano = 'ESSENTIAL';

    const loadPreloadScripts = () => {
      document.getElementsByTagName('body')[0].appendChild(preloadChart);
      window.removeEventListener('scroll', loadPreloadScripts);
    };
    window.addEventListener('scroll', loadPreloadScripts);
  } catch (error) {
    console.error(`onClientEntry: ${error}`);
  }
};

const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  const { href, search } = window.location;

  if (window.dataLayer) {
    window.dataLayer.push({ originalLocation: href });
  }

  if (search.includes('showEditLink=1')) {
    window.localStorage.setItem('showEditLink', '1');
  }
};

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ prevLocation }) => {
  let { pathname } = window.location;

  if (pathname === '/' || pathname === 'pages/') {
    pathname = 'homepage';
  }

  if (prevLocation && !(prevLocation as Location).pathname.includes('/contact/')) {
    // set session storage so we can use it on single page marketo form
    try {
      sessionStorage.setItem('previousPageviewId', getCookie('lt-pageview-id') || '');
      sessionStorage.setItem('prevLocation', (prevLocation as Location).pathname);
    } catch {
      // no-op
    }
  }
};

export { onClientEntry, onInitialClientRender, onRouteUpdate };

import type { GatsbyBrowser } from 'gatsby';

let hasLoaded = false;

const MARKETO_ACCOUNT_ID = '704-BEL-366';
// const MARKETO_ACCOUNT_SANDBOX_ID = '354-DBN-505';

const munchkinFunctionActivation = () => {
  if (window.Munchkin && window.Munchkin.munchkinFunction) {
    window.Munchkin.munchkinFunction('visitWebPage', {
      url: window.location.pathname,
    });
  }
};

const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  const munchkin = document.createElement('script');
  munchkin.src = 'https://munchkin.marketo.net/munchkin.js';
  munchkin.async = true;
  munchkin.id = 'munchkin-script';
  munchkin.dataset.osano = 'ESSENTIAL';

  munchkin.onload = () => {
    window.Munchkin?.init(MARKETO_ACCOUNT_ID);
    munchkinFunctionActivation();
    hasLoaded = true;
  };

  document.head.appendChild(munchkin);
};

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  // Munchkin isn't made for SPA so we must mimic what it
  // does on landing after any route changes which is to
  // log the visited page and log any clicked links
  if (hasLoaded) {
    munchkinFunctionActivation();
  }
};

export { onInitialClientRender, onRouteUpdate };

const qualified = /* js */ `
(function (w, q) {
  w['QualifiedObject'] = q;
  w[q] =
    w[q] ||
    function () {
      (w[q].q = w[q].q || []).push(arguments);
    };
})(window, 'qualified')`;

export default qualified;

import type { Lucid } from '../types/types';

const environmentOptions = ['development', 'test', 'production', 'preprod', 'staging'];

const isValidEnv = (value?: string): value is Lucid.Environment.Keys =>
  value ? environmentOptions.includes(value) : false;

const getEnv = (): Lucid.Environment.Keys => {
  const currentEnv = process.env.NODE_ENV;

  if (!isValidEnv(currentEnv)) return 'development';

  return currentEnv;
};

const getActiveEnv = (): Lucid.Environment.Keys => {
  const currentActiveEnv = process.env.GATSBY_NODE_ENV;

  if (!isValidEnv(currentActiveEnv)) return 'development';

  return currentActiveEnv;
};

export const isDev = () => 'development' === getEnv();
export const isTest = () => 'test' === getEnv();
export const isProd = () => 'production' === getEnv();
export const isPreProd = () => 'production' === getEnv() && 'preprod' === getActiveEnv();
export const isStaging = () => 'production' === getEnv() && 'staging' === getActiveEnv();

export default getEnv;

const getAppDomain = (url: string) => {
  if (url.includes('.staging.')) {
    return 'lucidstaging.app';
  }

  if (url.includes('.preprod.') || url.includes('preprod')) {
    return 'lucidpreprod.app';
  }

  if (url.includes('.dev.') || url.includes('localhost')) {
    return 'luciddev.app';
  }

  return 'lucid.app';
};

export default getAppDomain;

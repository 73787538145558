import React from 'react';

import { type GatsbyBrowser, Script } from 'gatsby';
import { setupCookieSync, isBrowser, isDev, scripts } from '@lucid/utils';

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  const maxAttempts = 3;
  let attempt = 0;

  const recordPage = () => {
    try {
      if (window.lucid && window.lucid.beacon) {
        window.lucid.beacon.recordPageChange(location.href);
      }
    } catch (e) {
      if (attempt < maxAttempts) {
        attempt += 1;
        setTimeout(() => recordPage(), 100);
      } else {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  if (isBrowser()) {
    if (prevLocation && window.lucid && window.lucid.beacon && !!window.lucid.beacon.recordPageChange) {
      setTimeout(() => recordPage(), 200);
    }

    if ('qualified' in window) {
      window.qualified('page');
    }
  }
};

const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  const iframeLocation = (window && window.location) || null;

  setupCookieSync(iframeLocation);
};

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  if ('qualified' in window || isDev()) {
    return element;
  }

  return (
    <>
      {element}
      <Script key="qualified-init" id="qualified-init" strategy="idle">
        {scripts.qualified}
      </Script>
      <Script
        key="qualified-external"
        async
        id="qualified-external"
        src="https://js.qualified.com/qualified.js?token=gtGrXHe9GAjt2evZ"
        strategy="idle"
      />
    </>
  );
};

export { onRouteUpdate, onInitialClientRender, wrapPageElement };

const isBrowser = () => !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const isBrowserIE = (): boolean => {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      return true;
    }

    return false;
  }

  return false;
};

export default isBrowser;
